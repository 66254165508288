import React from "react";
import "../../App.scss";
import Footer from "../Footer";

export default function Service() {
  return (
    <>
      <div className="container">
        <header id="#service">
          <h1>SERVICE</h1>
          <p>
            UI/UXデザインからHTML、CSS、JavaScrptコーディング、Webアプリ開発まで対応いたします。
          </p>
        </header>
      </div>
      <Footer />
    </>
  );
}
