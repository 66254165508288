import React from "react";
import "../App.scss";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/video-1.mp4" autoPlay loop muted />
      <h1>PORTFOLIO</h1>
      <p>Design & Develop</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          GET CONTACT
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
