import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/profile">Profile</Link>
            <Link to="/works">Works</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/contact">Contact</Link>
            {/* <Link to="/">Sponsorships</Link> */}
          </div>
        </div>
      </div>
      <section className="social-media">
        <small className="website-rights">© 2022</small>
      </section>
    </div>
  );
}

export default Footer;
