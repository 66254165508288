import React from "react";
import "../../App.scss";
import "./Home.scss";
import Cards from "../Cards";
import HeroSection from "../HeroSection";
import Footer from "../Footer";

function Home() {
  return (
    <>
      <HeroSection />
      <div className="home_container">
        <Cards />
      </div>
      <Footer />
    </>
  );
}

export default Home;
