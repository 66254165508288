import React from "react";
import { Link } from "react-router-dom";
import "../../App.scss";
import "./Profile.scss";
import Footer from "../Footer";

export default function Profile() {
  return (
    <>
      <div className="container">
        <header id="#profile">
          <h1>PROFILE</h1>
          <p>
            お問い合わせやご依頼はお気軽に<Link to="/contact">お問い合わせフォーム</Link>にてご連絡ください。
          </p>
        </header>
        <div className="page-layout">
          <dl>
            <dt>事業形態</dt>
            <dd>個人事業主　ウェブエンジニア</dd>
            {/* <dt>事業主</dt>
            <dd>齊藤　美沙希</dd> */}
            <dt>事業内容</dt>
            <dd>
              ウェブサイト制作・アプリケーション開発
              <br />
              <small>
                サイト設計・ウェブデザイン・コーディング・システム開発など
              </small>
            </dd>
            {/* <dt>住所</dt>
            <dd>東京都目黒区</dd> */}
            {/* <dt>MAIL</dt>
            <dd>
              <a href="mailto:doll.rika01@gmail.com">doll.rika01@gmail.com</a>
            </dd> */}
            {/* <dt>住所</dt>
            <dd>東京都目黒区</dd> */}
          </dl>
          <div className="small-wrap">
            <p>
              略歴： レコールバンタン卒 <span className="separator">｜</span>{" "}
              某ソフトウェア会社入社／アプリ開発、UI・UX作成
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
