import React, { useState } from "react";
import "../../App.scss";
import "./Contact.scss";
import Footer from "../Footer";

export default function Contact() {
  const [state, setState] = useState({});

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };
  

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    console.log(setState)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        window.location.href = form.getAttribute("action");
      })
      .catch((error) => alert(error));
  };
  
  return (
    <>
      <div className="container">
        <header id="#contact">
          <h1>CONTACT</h1>
          <p>お気軽にお問い合わせください。</p>
        </header>
        <form name="contact" method="POST" action="/thanks" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" placeholder="お名前" name="name" onChange={handleChange} required />
          <input type="email" placeholder="メールアドレス" name="email" onChange={handleChange} required />
          <textarea placeholder="詳細内容をご入力ください" name="message" onChange={handleChange} required />
          <input type="submit" value="送信" />
        </form>
        <div className="page-layout">
          <div className="border-wrap">
            <ul>
              <li>返信がない場合は「迷惑メール」フォルダもご確認ください。</li>
              <li>営業目的のメールには返信しておりません。</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
