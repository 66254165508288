import React from "react";
import Navbar from "./components/Navbar";
import "./App.scss";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Profile from "./components/pages/Profile";
import Service from "./components/pages/Service";
import Works from "./components/pages/Works";
import Contact from "./components/pages/Contact";
import Thanks from "./components/pages/Thanks";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/profile" component={Profile} />
          <Route path="/service" component={Service} />
          <Route path="/works" component={Works} />
          <Route path="/contact" component={Contact} />
          <Route path="/thanks" component={Thanks} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
