import React from "react";
import "../../App.scss";
import Footer from "../Footer";
import Cards from "../Cards";

export default function Works() {
  return (
    <>
      <div className="container">
        <header id="#works">
          <h1>WORKS</h1>
          <p>過去の制作実績です。</p>
        </header>
        <Cards />
      </div>
      <Footer />
    </>
  );
}
