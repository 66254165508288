import React from "react";

export const BadgeText = (badgeName) => { 
  console.log(badgeName)
  if (badgeName === "gyomu") {
    return "業務"
  } else if (badgeName === "dev") {
    return "個人開発"
  } else if (badgeName === "hack") {
    return "ハッカソン"
  } else {
    return "余白埋め"
  }
}

function CardItem(props) {
  return (
    <>
      <li className="cards__item">
        <a href={props.path} className="cards__item__link">
          <figure className="cards__item__pic-wrap">
            <img className="cards__item__img" alt={props.src} src={props.src} />
          </figure>
          <div className="cards__item__info">
            <h5 className="cards__item__text"><span className={`${props.badgeName}-badge`}>{BadgeText(props.badgeName)}</span>{props.text}</h5>
          </div>
        </a>
      </li>
    </>
  );
}

export default CardItem;
