import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <div className="cards__container">
        <ul className="cards__items">
          <CardItem
            src="images/deco.webp"
            text="世界でひとつのオリジナルスマホケースが簡単に作れるアプリ デザインケース HP"
            badgeName="gyomu"
            path="https://scrapbox.io/works-for-portfolio/%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E3%82%B1%E3%83%BC%E3%82%B9_HP"
          />
          <CardItem
            src="images/deco-admin.webp"
            text="商品、ブログ記事、お問い合わせ等CMS デザインケース 管理画面"
            badgeName="gyomu"
            path="https://scrapbox.io/works-for-portfolio/%E7%AE%A1%E7%90%86%E7%94%BB%E9%9D%A2_CMS"
          />
        </ul>
        <ul className="cards__items">
          <CardItem
            src="images/meguroku-hokatsu.webp"
            text="目黒区に特化した保活サイト 目黒区保活攻略ノート"
            badgeName="dev"
            path="https://scrapbox.io/works-for-portfolio/%E7%9B%AE%E9%BB%92%E5%8C%BA%E4%BF%9D%E6%B4%BB%E6%94%BB%E7%95%A5%E3%83%8E%E3%83%BC%E3%83%88"
          />
          <CardItem
            src="images/fit.webp"
            text="オリジナルパンプスが作れるモバイルアプリ Fit UI/UX作成"
            badgeName="gyomu"
            path="https://scrapbox.io/works-for-portfolio/%E3%82%AA%E3%83%AA%E3%82%B8%E3%83%8A%E3%83%AB%E3%83%91%E3%83%B3%E3%83%97%E3%82%B9%E3%81%8C%E4%BD%9C%E3%82%8C%E3%82%8B%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%82%A2%E3%83%97%E3%83%AAUIUX"
          />
          <CardItem
            src="images/iko-tube.webp"
            text="生駒卓也くんの動画検索サービス"
            badgeName="dev"
            path="https://scrapbox.io/works-for-portfolio/%E7%94%9F%E9%A7%92%E5%8D%93%E4%B9%9F%E3%81%8F%E3%82%93%E3%81%AE%E5%8B%95%E7%94%BB%E6%A4%9C%E7%B4%A2%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9"
          />
        </ul>
        <ul className="cards__items">
          <CardItem
            src="images/pola.webp"
            text="POLA BUSINESS BUILD"
            badgeName="hack"
            path="https://scrapbox.io/works-for-portfolio/POLA_BUSINESS_BUILD"
          />
          <CardItem
            src="images/yahoo.webp"
            text="Yahoo! JAPAN Hack Day"
            badgeName="hack"
            path="https://scrapbox.io/works-for-portfolio/HackDay2019"
          />
          <CardItem
            src="images/delisense.webp"
            text="毎日の料理を楽しくするスマートキッチン体験をプロトタイピングする"
            badgeName="hack"
            path="https://scrapbox.io/works-for-portfolio/毎日の料理を楽しくするスマートキッチン体験をプロトタイピングする"
          />
        </ul>
        <ul className="cards__items">
          <CardItem
            src="images/tapikkason.webp"
            text="タピオカ大好き開発イベント「タピッカソン」 タピオカタッチゲームアプリ"
            badgeName="hack"
            path="https://scrapbox.io/works-for-portfolio/%E3%82%BF%E3%83%94%E3%83%83%E3%82%AB%E3%82%BD%E3%83%B3"
          />
          <CardItem
            src="images/paco.webp"
            text="ユーザー投稿型恋愛マッチングアプリメディアサイト"
            badgeName="gyomu"
            path="https://scrapbox.io/works-for-portfolio/%E6%81%8B%E6%84%9B%E3%83%9E%E3%83%83%E3%83%81%E3%83%B3%E3%82%B0%E7%B3%BB%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E3%82%B5%E3%82%A4%E3%83%88"
          />
          <CardItem
            src="images/portfolio.webp"
            text="ポートフォリオサイト"
            badgeName="dev"
            path="/"
          />
        </ul>
        <ul className="cards__items">
          <CardItem
            src="images/grittoday.webp"
            text="毎日の健康をサポートするサラダショップ GRIT TODAY HP"
            badgeName="gyomu"
            path="https://scrapbox.io/works-for-portfolio/GRIT_TODAY_HP"
          />
          <CardItem
            src="images/udon.webp"
            text="架空の飲食店（うどん屋）のオリジナルサイト"
            badgeName="dev"
            path="https://scrapbox.io/works-for-portfolio/%E6%9E%B6%E7%A9%BA%E3%81%AE%E9%A3%B2%E9%A3%9F%E5%BA%97%EF%BC%88%E3%81%86%E3%81%A9%E3%82%93%E5%B1%8B%EF%BC%89%E3%81%AE%E3%82%AA%E3%83%AA%E3%82%B8%E3%83%8A%E3%83%AB%E3%82%B5%E3%82%A4%E3%83%88"
          />
          <CardItem
            src="images/vue3-portfolio.webp"
            text="Vue3で作ったポートフォリオサイト"
            badgeName="dev"
            path="https://vue3-portfolio.netlify.app/"
          />
        </ul>
        <ul className="cards__items">
          <CardItem
            src="images/senbaduru.webp"
            text="千羽鶴プロジェクト"
            badgeName="dev"
            path="https://scrapbox.io/works-for-portfolio/%E5%8D%83%E7%BE%BD%E9%B6%B4%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88"
          />
          <CardItem
            src="images/noimage.webp"
            text="texttexttexttexttexttexttexttexttexttext"
            badgeName=""
            path=""
          />
          <CardItem
            src="images/noimage.webp"
            text="texttexttexttexttexttexttexttexttexttext"
            badgeName=""
            path=""
          />
        </ul>
      </div>
    </div>
  );
}

export default Cards;
